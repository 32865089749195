/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route
*/
import React, { lazy } from "react";
//import AppOptions from "views/pages/administration/AppOptions";

// Material Dashboard 2 PRO React layouts
const AccountSettings = lazy(() => import("views/pages/account/settings"));
const Users = lazy(() => import("views/pages/administration/Users"));
const SMTPSettings = lazy(() => import("views/pages/administration/SMTPSettings"));
const Roles = lazy(() => import("views/pages/administration/Roles"));
const Permissions = lazy(() => import("views/pages/administration/Permissions"));
const AppOptions = lazy(() => import("views/pages/administration/AppOptions"));

const Calendar = lazy(() => import("views/pages/Calendar"));

const JobsOverview = lazy(() => import("views/pages/jobs/JobsOverview"));
const PlanJobRequest = lazy(() => import("views/pages/jobs/PlanJobRequest"));
// const EditJobRequest = lazy(() => import("views/pages/jobs/EditJobRequest"));
const ScheduleJobRequest = lazy(() => import("views/pages/jobs/ScheduleJobRequest"));
const ConfirmJobRequest = lazy(() => import("views/pages/jobs/ConfirmJobRequest"));
const CompleteJobRequest = lazy(() => import("views/pages/jobs/CompleteJobRequest"));

const ClientsView = lazy(() => import("views/pages/clients/ClientsView"));
const NewClient = lazy(() => import("views/pages/clients/NewClient"));
const EditClient = lazy(() => import("views/pages/clients/EditClient"));
const EditClientSite = lazy(() => import("views/pages/clients/EditClientSite"));
const NewClientSite = lazy(() => import("views/pages/clients/NewClientSite"));

// const JobSchedule = lazy(() => import("views/pages/JobSchedule"));

// const SignInIllus = lazy(() => import("views/authentication/sign-in/basic/illustration"));
const SignInBasic = lazy(() => import("views/authentication/sign-in/basic"));
const Error404 = lazy(() => import("views/pages/error-pages/Error404"));
// const Error401 = lazy(() => import("views/pages/error-pages/Error401"));

const protectedRoutes = [
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Clients",
    key: "clients",
    permissions: ["read:clients"],
    route: "/clients",
    icon: "people",
    component: <ClientsView />,
    noCollapse: true,
  },
  {
    type: "collapse",
    icon: "edit_calendar",
    name: "Calendar",
    key: "calendar",
    permissions: ["read:job_requests"],
    route: "/calendar",
    component: <Calendar />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Jobs",
    key: "job-requests",
    permissions: ["read:job_requests"],
    route: "/job-requests",
    icon: "list_alt",
    component: <JobsOverview />,
    noCollapse: true,
  },
  { type: "divider", key: "divider-1" },
  {
    type: "collapse",
    name: "Administration",
    key: "administration",
    icon: "admin_panel_settings_rounded",
    collapse: [
      {
        type: "collapse",
        icon: "manage_accounts_rounded",
        name: "Users",
        key: "users",
        permissions: ["read:users"],
        route: "/administration/users",
        component: <Users />,
      },
      {
        type: "collapse",
        icon: "mark_email_read_outline_rounded",
        name: "SMTP Settings",
        key: "smtp-settings",
        permissions: ["read:smtp_email_provider"],
        route: "/administration/smtp-settings",
        component: <SMTPSettings />,
      },
      {
        type: "collapse",
        icon: "settings_rounded",
        name: "Roles",
        key: "roles",
        permissions: ["read:roles"],
        route: "/administration/roles",
        component: <Roles />,
      },
      {
        type: "collapse",
        icon: "settings_rounded",
        name: "Permissions",
        key: "permissions",
        permissions: ["read:permissions"],
        route: "/administration/permissions",
        component: <Permissions />,
      },
      {
        type: "collapse",
        icon: "settings_rounded",
        name: "App Options",
        key: "app-options",
        route: "/administration/app-options",
        component: <AppOptions />,
      },
    ],
  },
];

const otherProtectedRoutes = [
  {
    name: "Profile",
    key: "profile",
    permissions: ["read:user"],
    route: "/profile",
    icon: "manage_accounts",
    component: <AccountSettings />,
  },
  {
    name: "New Client",
    key: "new-client",
    permissions: ["create:clients"],
    route: "/clients/new",
    icon: "people",
    component: <NewClient />,
  },
  {
    name: "Edit Client",
    key: "edit-client",
    permissions: ["update:clients"],
    route: "/clients/:id",
    icon: "people",
    component: <EditClient />,
  },
  {
    name: "New Client-Site",
    key: "new-client-site",
    permissions: ["create:client_sites"],
    route: "/clients/:clientId/client-sites/new",
    icon: "people",
    component: <NewClientSite />,
  },
  {
    name: "Edit Client-Site",
    key: "edit-client-site",
    permissions: ["update:client_sites"],
    route: "/clients/:clientId/client-sites/:clientSiteId",
    icon: "people",
    component: <EditClientSite />,
  },
  {
    name: "New Job Request",
    key: "new-job-requests",
    permissions: ["create:job_requests"],
    route: "/job-requests/new",
    icon: "list_alt",
    component: <PlanJobRequest />,
  },
  {
    name: "Edit Job Request",
    key: "edit-job-requests",
    permissions: ["update:job_requests"],
    route: "/job-requests/:id",
    icon: "list_alt",
    component: <PlanJobRequest />,
  },
  {
    name: "Schedule Job Request",
    key: "schedule-job-requests",
    permissions: ["update:job_requests"],
    route: "/job-requests/schedule/:id",
    icon: "list_alt",
    component: <ScheduleJobRequest />,
  },
  {
    name: "Confirm Job Request",
    key: "confirm-job-requests",
    permissions: ["update:job_requests"],
    route: "/job-requests/confirm/:id",
    icon: "list_alt",
    component: <ConfirmJobRequest />,
  },

  {
    name: "Complete Job Request",
    key: "complete-job-requests",
    permissions: ["update:job_requests"],
    route: "/job-requests/complete/:id",
    icon: "list_alt",
    component: <CompleteJobRequest />,
  },
];

const publicRoutes = [
  {
    name: "Page Not Found",
    key: "page-not-found",
    route: "/page-not-found",
    component: <Error404 />,
  },
];

const authRoutes = [
  {
    name: "Login",
    key: "login",
    route: "/login",
    component: <SignInBasic />,
  },
];

export { publicRoutes, authRoutes, otherProtectedRoutes };
export default protectedRoutes;
